import React, { useState, useEffect } from 'react';
import { AppBar, Box, Tab, Tabs, Grid, makeStyles, useTheme, Typography, LinearProgress } from '@material-ui/core';
import moment from 'moment';
import appVars from '../../appVars.json';
import Navbar from '../../Navbar';
import LightGrid from '../../LightGrid';
import PropTypes from 'prop-types';



var _ = require('lodash');


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        ...theme.mixins.toolbar,
    }
}));







function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}







const TableView = (props) => {
    // eslint-disable-next-line
    const [hasError, setErrors] = useState(false);
    const [cards, setCards] = useState([]);
    const [value, setvalue] = useState(0);
    const [incards, setinCards] = useState([]);
    const [upcards, setupCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const theme = useTheme();



    const handleChange = (event, newValue) => {
        setvalue(newValue);
    };




    async function fetchData(id) {

        // console.log('ID:' + id);
        var url = appVars.api_url + "/lightboxes/" + id;
        if (id === 'lightbox') {
            url = appVars.api_url + "/lightboxes/all";

        }

        const res = await fetch(url);
        // console.log(res);
        res
            .json()
            .then(res => {
                //  console.log(res);
                var d = _.filter(res, function (o) {

                    if (moment().isBetween(o.startTime, o.endTime)) {
                        return o;
                    }


                });

                var e = _.filter(res, function (o) {

                    if (moment().isAfter(o.endTime)) {
                        return o;
                    }


                });

                var f = _.filter(res, function (o) {

                    if (moment().isBefore(o.endTime) && moment().isBefore(o.startTime)) {
                        return o;
                    }


                });
                //   console.log(f);
                setinCards(e);
                setupCards(_.reverse(f))
                setCards(_.reverse(d));
            })
            .then(res => setLoading(false))
            .catch(err => setErrors(true));

        //  console.log(upcards);
    }


    useEffect(() => {
        const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1);
        const id = getLastItem(window.location.href);
        fetchData(id);


    }, []);



    return (


        <div>
            <div className={classes.toolbar} />
            <div className={classes.root}>
                <Navbar siteTitle={'Lightwerks'}></Navbar>

                <Grid container xs={12} spacing={0} direction="row-reverse" >

                    <Grid item xs={12}>

                        <AppBar position="static" color="secondary">
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" indicatorColor="secondary">
                                <Tab label="Currently Running" {...a11yProps(0)} />
                                <Tab label="Upcoming Lightboxes" {...a11yProps(1)} />
                                <Tab label="inAcive Lightboxes" {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>

                    </Grid>

                    <Grid item xs={12} style={{ marginTop: theme.spacing(1), paddingLeft: 7, paddingRight: 7, borderLeft: "1px solid #e4e4e4", height: "calc(100vh - 70px)" }}>
                        {(!loading ?
                            <Grid container spacing={2}>
                                <Grid md={12} item>
                                    <TabPanel value={value} index={0}>
                                        <Typography variant='h6' gutterBottom>Running Lightboxes</Typography>
                                        <LightGrid cards={cards}></LightGrid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Typography variant='h6' gutterBottom>Upcoming Lightboxes</Typography>
                                        <LightGrid cards={upcards}></LightGrid>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <Typography variant='h6' gutterBottom>Expired Lightboxes</Typography>
                                        <LightGrid cards={incards}></LightGrid>
                                    </TabPanel>
                                </Grid>


                            </Grid>
                            : <LinearProgress style={{ margin: ('20%', '25%') }} color="secondary"></LinearProgress>)}


                    </Grid>


                </Grid>

            </div>
        </div>














    );
};

TableView.propTypes = {

};

export default TableView;
