import React, { useState, useEffect } from 'react';
import appVars from '../../../appVars.json';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, LinearProgress, Box, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core';
import { useTheme } from '@material-ui/core';

const ClientSearch = (props) => {
    const [domains, setDomains] = useState();
    const theme = useTheme();
    //

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/tagmanagers");
        res
            .json()
            .then((res) => {
                setDomains(res);
                //console.log(res);
                //  setLoading(false);
            })
            .catch((err) => console.log(err.msg));

    }


    useEffect(() => {
        fetchData();
    }, []);





    return (

            <div>
                {(domains ?
                    <Autocomplete
                        id="client-id"
                        style={{width:500}}
                        groupBy={(option) => option.clientname}
                        options={domains.sort((a, b) => -b.clientname.localeCompare(a.clientname))}
                        getOptionLabel={(option) => option.name + ' ' + option.siteUrl}
                        renderInput={(params) => <TextField {...params} color='secondary' label="Domains Search" size="small" fullWidth variant="outlined" />}
                        renderOption={(option) => (
                            <Box display="flex" alignItems="center">
                                <ListItemIcon>
                                    <Avatar
                                        style={{background:theme.palette.primary.dark}}
                                        variant="square"
                                        alt={option.name}
                                      src={`${appVars.api_url}/imageFiles/${option.id}.png`} 
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option.name} />
                            </Box>
                        )}
                        {...props}
                    />
                    : <LinearProgress />)}
            </div>
   

    );
};

ClientSearch.propTypes = {

};

export default ClientSearch;
