import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createTheme, ThemeProvider, makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, CardHeader, Grid, LinearProgress, Typography, TextField, MenuItem, Avatar } from '@material-ui/core';
import appVars from '../../appVars.json';
import { lightenHexColor } from '../../dwi-functions';
import { Redirect } from 'react-router-dom';
import PlatformSearch from '../controls/platform-search';
import { maxWidth } from '@mui/system';


const SetUp = (prop) => {






    const location = useLocation();
    const id = location.pathname.split("/")[3];
    const clientid = location.pathname.split("/")[4];


    const searchParams = new URLSearchParams(location.search);
    const [client, setClient] = useState();
    const [users, setUsers] = useState([]);
    const [owner, setOwner] = useState();
    const [platform, setPlatform] = useState();
    const [brandColor, setbrandColor] = useState({ primary: "#27474E", secondary: "#fff" });

    const theme = createTheme({
        overrides: {
            // Style sheet name ⚛️
            MuiCardHeader: {
                // Name of the rule
                root: {
                    // Some CSS
                    backgroundColor: brandColor.primary,
                    color: brandColor.secondary,
                },
            },
            MuiCardContent: {
                root: {
                    padding: 0
                }
            }
        },
    });

    const BorderLinearProgress = withStyles((theme) => ({
        root: {
            height: 10,
            borderRadius: 5,
        },
        colorPrimary: {
            backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
        },
        bar: {
            borderRadius: 5,
            backgroundColor: brandColor.primary,
        },
    }))(LinearProgress);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(2),
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                margin: theme.spacing(8),
            }

        },
        media: {
            maxWidth: 150
        }
    }));
    const classes = useStyles();




    async function fetchData() {

        const res = await fetch(appVars.api_url + "/tagmanager/" + id);
        res
            .json()
            .then(res => {
                res.pkRecId = id;
                setClient(res);
                document.title = res.clientname + ": " + res.name;
            })

        var url = appVars.api_url + "/users/c/" + clientid;
        // console.log(url);
        const ures = await fetch(url);
        ures
            .json()
            .then((users) => {
                setUsers(users);
                //console.log(clientTypes);
                console.log(users);
            })

    }

    useEffect(() => {
        fetchData();



        return () => {

        };
    }, []);





    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>

                {(client && clientid ?
                    <Grid container justifyContent="center" spacing={2}>

                        <Grid item md={8}>
                            <Card>
                                <CardHeader titleTypographyProps={{ variant: 'h6', align: "center" }} title={<img className={classes.media} src={appVars.api_url + '/imageFiles/' + clientid + '.png'} alt={client.clientname} ></img>} ></CardHeader>
                                <CardContent margin={0}>

                                    <Box bgcolor={lightenHexColor(brandColor.primary, 50)} p={.5} m={0}>
                                        <Typography variant="body1">Name: {client.name}</Typography>
                                    </Box>
                                    <Box p={2} m={0} mt={4}>
                                        <TextField size="small" fullWidth select variant='outlined' inputProps={{ fullWidth: true }} InputLabelProps={{
                                            shrink: true
                                        }} label="Owner"
                                            onChange={(e) => {
                                                setOwner(e.target.value);
                                                //  console.log(e.target.value);
                                            }}>
                                            {
                                                users.map((entry) => (
                                                    <MenuItem key={entry.pkRecID} value={entry.pkRecID}>{entry.name}</MenuItem>
                                                ))}

                                        </TextField>

                                    </Box>
                                    <Box p={2} m={0} mt={0}>
                                        <PlatformSearch
                                            title="Donation Platform"
                                            onChange={(e) => {
                                                setPlatform(e.target.value);
                                            }}
                                        ></PlatformSearch>
                                    </Box>



                                </CardContent>
                            </Card>


                        </Grid>

                    </Grid>
                    : <BorderLinearProgress color="primary"></BorderLinearProgress>)}

            </ThemeProvider>

        </div>
    );
};

SetUp.propTypes = {

};

export default SetUp;
