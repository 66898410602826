import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, LinearProgress, Grid } from '@material-ui/core/';
import appVars from '../../../appVars.json';
import Logo from "../../../images/StackAdapts-New-Logo.png";

const StackAdaptTokens = ({ field, form, ...props }) => {

    const [apiKeys, setApiKeys] = useState([]);
    const [api, setApi] = useState(props.saKey);



    async function fetchData() {
        var url = appVars.api_url + "/stackadapt/api/tokens";
        const res2 = await fetch(url);
        res2
            .json()
            .then((res2) => {
                setApiKeys(res2);
                //console.log(v);
            })
            .catch((err) => {
                //setErrors({ status: true, msg: err.message });
                //console.log(err);
            });
    }


    useEffect(() => {
        fetchData();
        return () => {

        };
    }, []);




    return (

        <div>
            {(apiKeys ?


                <Grid container>
                    <Grid item sm={6}>
                      <img src={Logo} alt="Stackadapt Logo" />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            variant='outlined'
                            shrink
                            label="Select Available Token"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            color="primary"
                            fullWidth
                            value={api}
                            select
                            onChange={(e) => {
                                props.setSelectedApiKey(e);
                                setApi(e.target.value);
                            }}
                            {...field} {...props}>
                            <MenuItem value="">Select One</MenuItem>
                            {
                                apiKeys.map((entry) => (
                                    <MenuItem key={entry.token} value={entry.token}>{entry.name}</MenuItem>
                                ))}

                        </TextField>
                    </Grid>
                </Grid>


                : <LinearProgress />)}
        </div>

    );
};

StackAdaptTokens.propTypes = {

};

export default StackAdaptTokens;
