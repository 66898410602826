// Consolidating imports from '@material-ui/core'
import {
    AppBar,
    Box,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useTheme,
    LinearProgress,
    Checkbox,
    FormControlLabel,
    Tab,
    Tabs,
    TextField,
    MenuItem
} from '@material-ui/core';

// Importing React and hooks
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Importing custom utilities and components
import appVars from '../../appVars.json';
import FileUploads from '../file-uploads';
import { lightenHexColor, rgbToHex } from '../../dwi-functions';
import { useLocation } from 'react-router-dom';
import { FcSettings } from "react-icons/fc";

import ColorPicker from 'material-ui-color-picker';
import ColorThief from 'colorthief';
import AdAccounts from '../../clients/controls/ad-accounts';
import { useToken } from '../../Login/TokenProvider';
import { ThemeProvider } from '@material-ui/core';
import _ from "lodash";
import { Skeleton } from '@material-ui/lab';
import StackAdaptTokens from '../../clients/controls/stackadapt-tokens';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={6}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};






const ReportSettings = (props) => {
    const location = useLocation();
    var id = location.pathname.split("/")[2];
    const token = useToken();
    const admintoken = token.token;
    const theme = useTheme();


    const [addOpen, setaddOpen] = useState(false);

    const [settingsOpen, setSettingsOpen] = useState(false);
    const [settings, setSettings] = useState();
    const [profile, setProfile] = useState();


    const [color, setColor] = useState(props.themeColor);
    const [colors, setColors] = useState();
    // eslint-disable-next-line
    const [profileID, setProfileID] = useState((props.id ? props.id : id))
    const [showOverview, setShowOverview] = useState(false);
    const [showVirtuous, setShowVirtuous] = useState(false);
    const [showConversions, setShowConversions] = useState(false);
    const [value, setValue] = useState(0);
    // eslint-disable-next-line
    const [adType, setAdType] = useState([{ id: "google_paid", name: "Google Paid" }, { id: "google_grant", name: "Google Grant" }, { id: "meta", name: "Meta" }, { id: "bing", name: "Bing" }, { id: "stackadapt", name: "Display" }, { id: "overview", name: "Overview" }, { id: "virtuous", name: "Virtuous" }]);




    const TagTypes = ({ field, form, ...props }) => {

        // console.log(adType);
        const filteredAdTypes = _.filter(adType, adTypes => !_.has(profile.settings.ad_sets, adTypes.id));

        console.log(filteredAdTypes);
        // setAdType(filteredAdTypes);


        //console.log(k);
        return <TextField
            fullWidth
            size='large'
            // style={{ marginBottom: theme.spacing(0) }}
            label="Add New Tab"
            InputLabelProps={{ shrink: true }}

            variant='outlined'
            select  {...field} {...props}
            onChange={(e) => {
                var v = {};


                switch (e.target.value) {
                    case "google_paid":
                        v = {
                            "google_paid": {
                                "id": "",
                                "tablabel": "Google Paid"
                            }
                        }
                        break;

                    case "google_grant":
                        v = {
                            "google_grant": {
                                "id": "",
                                "tablabel": "Google Grant"
                            }
                        }
                        break;
                    case "meta":
                        v = {
                            "meta": {
                                "id": "",
                                "tablabel": "Meta"
                            }
                        }
                        break;

                    case "bing":
                        v = {
                            "bing": {
                                "id": "",
                                "tablabel": "Bing"
                            }
                        }
                        break;
                    case "stackadapt":
                        v = {
                            "stackadapt": {
                                "id": "",
                                "tablabel": "Display"
                            }
                        }
                        break;

                    case "overview":
                        v = {
                            "overview": {
                                "id": 0,
                                "tablabel": "Overview"
                            }
                        }
                        break;
                    default:
                        break;
                }


                let updatedObj = {
                    "ad_sets": {
                        ...settings.ad_sets,
                        ...v
                    }
                };

                profile.settings = updatedObj;
                setSettings(updatedObj);
                setProfile(profile);
                // fetchData();
                console.log(profile);
            }
            }
        >
            {
                filteredAdTypes.map((entry) => (
                    <MenuItem key={entry.id} data-form={entry.name} value={entry.id}>{entry.name}</MenuItem>
                ))
            }
        </TextField >;
    };


    const handleSettingsOpen = () => {
        setSettingsOpen(true);
    };


    const handleClose = () => {
        setSettingsOpen(false);
    };

    const handleAddClose = () => {
        setaddOpen(false);
    };

    const handleOverviewChange = (event, newValue, label) => {
        if (settings.ad_sets['overview']) {
            delete settings.ad_sets['overview'];
        } else {
            settings.ad_sets['overview'] = { id: "0", tablabel: "Overview" };
        }
        //  console.log(settings.ad_sets);
        setShowOverview(!showOverview);
    };


    const handleConversionsChange = (event, newValue, label) => {
        if (settings.ad_sets['donations']) {
            delete settings.ad_sets['donations'];
        } else {
            settings.ad_sets['donations'] = { id: "0", tablabel: "Conversions" };
        }
        //  console.log(settings.ad_sets);
        setShowConversions(!showConversions);
    };

    const handleVirtuousChange = (event, newValue, label) => {
        if (settings.ad_sets['virtuous']) {
            delete settings.ad_sets['virtuous'];
        } else {
            settings.ad_sets['virtuous'] = { id: "0", tablabel: "Virtuous" };
        }
        //  console.log(settings.ad_sets);
        setShowVirtuous(!showVirtuous);
    };

    const setSelectedApiKey = (e) => {

        profile.saKey = e.target.value;

    }




    const handleChange = (event, newValue, label) => {

        var v = event.join(',');



        if (settings.ad_sets[newValue]) {
            settings.ad_sets[newValue].id = v;
        } else {
            settings.ad_sets[newValue] = { id: v, tablabel: label };
        }

        // console.log(settings);
        profile.settings = settings;
        setProfile(profile);
        setSettings(settings);

    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };




    const childToParent = (childdata) => {
        var f = childdata;
        const img = document.getElementById('avatar');
        img.src = f;
        console.log(f);
    };


    async function fetchData() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };


        const rp = await fetch(appVars.api_url + "/reportprofile/" + profileID, requestOptions);
        rp
            .json()
            .then((res3) => {

                if (res3 !== null) {

                    var sett = (typeof res3.settings === 'object' && res3.settings !== null ? res3.settings : JSON.parse(res3.settings));

                    var colorsD = (typeof res3.color === 'object' && res3.color !== null ? res3.color : JSON.parse(res3.color));

                    res3.settings = sett;
                    res3.tabs = JSON.parse(res3.tabs);
                    res3.color = colorsD;
                    setProfile(res3);
                    setSettings(sett);


                    if (res3.settings.ad_sets['overview']) {
                        setShowOverview(true);
                    }

                    if (res3.settings.ad_sets['virtuous']) {
                        setShowVirtuous(true);
                    }

                    if (res3.settings.ad_sets['donations']) {
                        setShowConversions(true);
                    }

                    // console.log(profile);






                } else {
                    profile.color = "#51704b";
                    profile.clientid = profileID;
                    profile.client = props.client
                    profile.settings = { "ad_sets": { "google_paid": { "id": "0" }, "google_grant": { "id": "0" }, "meta": { "id": "0" }, "bing": { "id": "0" }, "stackadapt": { "id": "0" } } }
                    profile.tabs = { "tabs": { "google_paid": { "label": "Google Paid", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "google_grant": { "label": "Google Grant", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "meta": { "label": "Meta", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "bing": { "label": "Bing", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } }, "stackadapt": { "label": "Stackadapt", "charts": { "impressions": 1, "clicks": 1, "ctr": 1, "cpc": 1, "conversions": 1, "revenue": 1, "conv_rate": 1, "cost_conversion": 1, "searchTerms": 1, "region": 0, "comment": 1 } } } }

                    setProfile(profile);
                    setSettings(profile.settings);
                    setSettingsOpen(true);
                }



                const img = new Image();
                img.crossOrigin = 'Anonymous'; // This is important for loading images from external URLs
                img.src = appVars.api_url + '/imageFiles/' + profileID + '.jpeg'; // Replace with your image's URL
                img.onload = () => {
                    const colorThief = new ColorThief();
                    let palette = colorThief.getPalette(img, 6);
                    //  console.log(palette);
                    setColors(palette); // Logs the color palette
                };




            })
            .catch((err) => {
                console.log(err);
            });




    }


    useEffect(() => {


        if (!settings) {
            fetchData();
        }


    });



    return (
        <ThemeProvider theme={theme}>
            <div>
                {(admintoken.token >= 6 ?
                    <div>
                        {(profile ?
                            <div>
                                {(props.icon ? <FcSettings size={30}
                                    onClick={handleSettingsOpen}
                                /> :

                                    <Button
                                        onClick={handleSettingsOpen}
                                        fullWidth
                                        style={{ color: theme.palette.primary.contrastText, marginBottom: theme.spacing(1) }}
                                        variant="contained"
                                        color='primary'
                                        startIcon={
                                            <FcSettings />
                                        }
                                    >Report Settings</Button>
                                )}



                                <Dialog
                                    open={settingsOpen}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    maxWidth="lg"
                                    fullWidth
                                >
                                    <DialogContent style={{ minWidth: 900 }} dividers >
                                        <DialogContentText id="alert-dialog-description">

                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <AppBar position="absolute" style={{ marginBottom: theme.spacing(3), marginTop: theme.spacing(0) }} >

                                                        <Tabs value={value} onChange={handleTabChange} aria-label="simple tabs example" variant="standard" scrollButtons="auto">
                                                            <Tab label="Styling" {...a11yProps(0)} />
                                                            <Tab label="Stackadapt API Token Setup" {...a11yProps(1)} />
                                                            <Tab label="AD Platforms" {...a11yProps(2)} />
                                                            <Tab label="Misc Settings" {...a11yProps(3)} />
                                                            {(admintoken.token >= 10 ? <Tab label="Color Suggestions" {...a11yProps(4)} /> : <Skeleton variant="rect" style={{ padding: theme.spacing(1) }} >Admin Access Needed</Skeleton>)}
                                                        </Tabs>
                                                    </AppBar>
                                                    <TabPanel value={value} index={0}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} xl={6}>
                                                                <div style={{ padding: theme.spacing(3), background: lightenHexColor("#000000", 70), textAlign: 'center', borderRadius: theme.spacing(1) }}>
                                                                    <img width={'100%'} id="avatar" src={appVars.api_url + '/imageFiles/' + id + '.png'} alt='' />
                                                                </div>
                                                                <FileUploads suggest="200px x 100px PNG ONLY" admintoken={admintoken} themeColor={profile.color} newid={id} childToParent={(e) => {
                                                                    //    console.log(e);
                                                                    childToParent(appVars.api_url + '/imageFiles/' + id + '.png');
                                                                }} ></FileUploads>
                                                            </Grid>

                                                            <Grid item xl={3} sm={12} xs={6} >
                                                                <ColorPicker
                                                                    type="text"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size='small'
                                                                    label="Primary"
                                                                    defaultValue={profile.color.primary}
                                                                    value={profile.color.primary}
                                                                    InputLabelProps={{
                                                                        style: {
                                                                            padding: theme.spacing(.15),
                                                                            background: profile.color.primary, color: profile.color.secondary, border: '1px solid ' + profile.color.secondary, borderRadius: '4px'
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        style: {

                                                                            background: "#fff", color: "#000"
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {

                                                                        var k = profile.color;
                                                                        k.primary = e;
                                                                        // console.log(k);
                                                                        // Create a new profile object with the updated color
                                                                        const updatedProfile = {
                                                                            ...profile,
                                                                            color: k,
                                                                        };

                                                                        // Update the profile state with the new object
                                                                        setProfile(updatedProfile);
                                                                        //  console.log(updatedProfile);
                                                                        // Assuming setColor is a setter function for another state variable that tracks the selected color
                                                                        setColor(k);
                                                                    }}
                                                                    style={{ marginBottom: theme.spacing(1) }}
                                                                />
                                                                <Box bgcolor={profile.color.primary} style={{ border: '1px solid ' + profile.color.secondary, display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 75, width: '100%', color: profile.color.secondary, borderRadius: theme.borderRadius }}>
                                                                    {profile.color.primary}
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xl={3} sm={12} xs={6}  >
                                                                <ColorPicker
                                                                    type="text"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    size='small'
                                                                    label="Secondary"
                                                                    defaultValue={profile.color.secondary}
                                                                    value={profile.color.secondary}
                                                                    InputLabelProps={{
                                                                        style: {
                                                                            padding: theme.spacing(.15),
                                                                            background: profile.color.secondary, color: profile.color.primary, border: '1px solid ' + profile.color.primary, borderRadius: theme.borderRadius
                                                                        }
                                                                    }}
                                                                    InputProps={{
                                                                        style: {

                                                                            background: "#fff", color: "#000"
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {

                                                                        var k = profile.color;
                                                                        k.secondary = e;
                                                                        //console.log(k);
                                                                        // Create a new profile object with the updated color
                                                                        const updatedProfile = {
                                                                            ...profile,
                                                                            color: k,
                                                                        };

                                                                        // Update the profile state with the new object
                                                                        setProfile(updatedProfile);
                                                                        //  console.log(updatedProfile);
                                                                        // Assuming setColor is a setter function for another state variable that tracks the selected color
                                                                        setColor(k);
                                                                    }}
                                                                    style={{ marginBottom: theme.spacing(1) }}
                                                                />
                                                                <Box bgcolor={profile.color.secondary} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 75, width: '100%', color: profile.color.primary, borderRadius: '4px' }}>
                                                                    {profile.color.secondary}
                                                                </Box>
                                                            </Grid>

                                                        </Grid>
                                                    </TabPanel>
                                                    <TabPanel value={value} index={1}>
                                                        <StackAdaptTokens
                                                            setSelectedApiKey={setSelectedApiKey}
                                                            saKey={profile.saKey}

                                                        />

                                                    </TabPanel>
                                                    <TabPanel value={value} index={2}>
                                                        {(settings ?
                                                            <Grid item xs={12} sm={12} xl={12}  >

                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} xs={12}>


                                                                        {
                                                                            Object.entries(settings.ad_sets).map((entry, i) => {
                                                                                //  console.log(entry);
                                                                                return (
                                                                                    <AdAccounts saKey={profile.saKey} admintoken={admintoken.token} themeColor={color} onSelectionChange={handleChange} size="small" style={{ marginBottom: theme.spacing(1) }} tab={entry[0]} id={entry[0]} name={entry[0]} placeholder={entry[1].tablabel} label={entry[1].tablabel} defaultValue={(entry[1] ? entry[1].id : '')} fullWidth />

                                                                                )
                                                                            })
                                                                        }



                                                                    </Grid>



                                                                    <Grid item sm={6} xs={12}>

                                                                        <TagTypes></TagTypes>
                                                                    </Grid>
                                                                </Grid>


                                                            </Grid>

                                                            : <LinearProgress />
                                                        )}
                                                    </TabPanel>

                                                    <TabPanel value={value} index={3} data-label="Misc Settings">

                                                        <Grid item xs={4}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={showOverview}
                                                                        onChange={handleOverviewChange}
                                                                        name="overview"
                                                                        color="secondary"
                                                                    />
                                                                }
                                                                label="Overview?"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={showVirtuous}
                                                                        onChange={handleVirtuousChange}
                                                                        name="virtuous"
                                                                        color="secondary"
                                                                    />
                                                                }

                                                                label="Virtuous?"
                                                            />

                                                        </Grid>
                                                        {(admintoken.token >= 10 ?
                                                            <Grid item xs={4}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={showConversions}
                                                                            onChange={handleConversionsChange}
                                                                            name="conversions"
                                                                            color="secondary"
                                                                        />
                                                                    }

                                                                    label="Conversions?"
                                                                />

                                                            </Grid>
                                                            : <Skeleton variant="rect" style={{ padding: theme.spacing(1) }} >Admin Access Needed</Skeleton>)}



                                                    </TabPanel>
                                                    <TabPanel value={value} index={4}>
                                                        {(admintoken.token >= 10 ?
                                                            <Grid item xl={12} sm={12} xs={12} style={{ background: lightenHexColor(profile.color.secondary, 25), borderRadius: theme.borderRadius, padding: theme.spacing(2), border: '1px solid ' + profile.color.secondary }}>
                                                                <Typography variant='h6'>Top 6 colors</Typography>
                                                                <Grid spacing={1} container>
                                                                    {(colors ?
                                                                        colors.map((entry, i) => (
                                                                            <Grid item xl={4} sm={6} xs={6}>
                                                                                <Box data-color={rgbToHex(entry)} color={lightenHexColor(rgbToHex(entry), 20)} style={{ height: '50px', width: "100%", marginBottom: theme.spacing(.5), marginTop: theme.spacing(1), display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#FFF', borderRadius: '4px', border: '1px solid #000' }} bgcolor={rgbToHex(entry)} onClick={(e) => {
                                                                                    profile.color = e.currentTarget.innerText;
                                                                                    setProfile(profile);
                                                                                    setColor(e.currentTarget.innerText)
                                                                                    //console.log();
                                                                                }} >
                                                                                    {rgbToHex(entry)}
                                                                                </Box>
                                                                            </Grid>
                                                                        ))
                                                                        : '')}
                                                                </Grid>
                                                            </Grid>
                                                            : <Skeleton variant="rect" style={{ padding: theme.spacing(1) }} >Admin Access Needed</Skeleton>)}

                                                    </TabPanel>




                                                </Grid>

                                            </Grid>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions

                                        style={{ background: "#f7f7f7" }}
                                    >
                                        <Button
                                            variant="outlined"
                                            style={{ color: profile.color.primary, background: profile.color.secondary, border: profile.color.primary }}
                                            onClick={handleClose} color="secondary">
                                            Close
                                        </Button>
                                        <Button
                                            style={{ color: profile.color.secondary, background: profile.color.primary, border: profile.color.secondary }}
                                            onClick={(e) => {

                                                // console.log('raw');
                                                var sett = (typeof profile.settings === 'object' && profile.settings !== null ? profile.settings : JSON.parse(profile.settings));
                                                var ads = (typeof profile.tabs === 'object' && profile.tabs !== null ? profile.tabs : JSON.parse(profile.tabs));
                                                var cols = (typeof profile.color === 'object' && profile.color !== null ? profile.color : JSON.parse(profile.color));

                                                var data = profile;

                                                delete data.updatedAt;
                                                delete data.createdAt;
                                                data.settings = JSON.stringify(sett);
                                                data.tabs = JSON.stringify(ads);
                                                data.color = JSON.stringify(cols);
                                                //setProfile(profile);
                                                var myHeaders = new Headers();
                                                myHeaders.append("Content-Type", "application/json");
                                                var raw = JSON.stringify(data);
                                                //console.log(raw);
                                                //return false;

                                                var requestOptions = {
                                                    method: 'POST',
                                                    headers: myHeaders,
                                                    body: raw,
                                                    redirect: 'follow'
                                                };

                                                fetch(appVars.api_url + "/reportprofile/", requestOptions)
                                                    .then(response => response.text())
                                                    .then(result => setSettingsOpen(false))
                                                    // .then(result =>window.location.reload()) 
                                                    .catch(error => console.log('error', error));







                                            }}

                                            variant="contained" color='primary'>Save</Button>
                                    </DialogActions>
                                </Dialog>

                                <Dialog
                                    open={addOpen}
                                    onClose={handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    maxWidth="lg"
                                >
                                    <DialogTitle id="alert-dialog-title">{<Typography variant="h5" gutterBottom>Add New</Typography>}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">

                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>

                                                </Grid>
                                                <Grid item >

                                                </Grid>


                                            </Grid>
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="contained"
                                            style={{ color: theme.palette.primary.contrastText, background: profile.color }}
                                            onClick={handleAddClose} >
                                            Close
                                        </Button>
                                        <Button
                                            style={{ color: theme.palette.primary.contrastText }}
                                            onClick={(e) => {

                                                // console.log('raw');
                                                var sett = (typeof profile.settings === 'object' && profile.settings !== null ? profile.settings : JSON.parse(profile.settings));
                                                var ads = (typeof profile.tabs === 'object' && profile.tabs !== null ? profile.tabs : JSON.parse(profile.tabs));

                                                var data = profile;

                                                delete data.updatedAt;
                                                delete data.createdAt;
                                                data.settings = JSON.stringify(sett);
                                                data.tabs = JSON.stringify(ads);

                                                //setProfile(profile);
                                                var myHeaders = new Headers();
                                                myHeaders.append("Content-Type", "application/json");
                                                var raw = JSON.stringify(data);
                                                //console.log(raw);
                                                //  return false;

                                                var requestOptions = {
                                                    method: 'POST',
                                                    headers: myHeaders,
                                                    body: raw,
                                                    redirect: 'follow'
                                                };

                                                fetch(appVars.api_url + "/reportprofile/", requestOptions)
                                                    .then(response => response.text())
                                                    .then(result => setSettingsOpen(false))
                                                    // .then(result =>window.location.reload()) 
                                                    .catch(error => console.log('error', error));







                                            }}

                                            variant="contained" color='primary'>Save</Button>
                                    </DialogActions>
                                </Dialog>



                            </div>
                            : <LinearProgress />)}

                    </div>
                    : '')}
            </div>
        </ThemeProvider>
    );
};

ReportSettings.propTypes = {

};

export default ReportSettings;
