import React, { useState, useEffect } from 'react';

import { Button, Divider, Grid, MenuItem, Typography, Card, CardContent, useTheme, CardActions, Link, Snackbar, CircularProgress, CardHeader, Avatar, ButtonGroup, Tooltip } from '@material-ui/core';
import { Add, Delete, CloudDownload, Edit, OpenInNew, HelpOutline } from '@material-ui/icons';
import ClipboardJS from "clipboard";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import appVars from '../../appVars.json';
import { Alert } from '@material-ui/lab';
import _ from "lodash";
import { lightenHexColor } from '../../dwi-functions';
import MaterialTable from 'material-table';

const Tags = (props) => {
    const [tag, setTag] = useState({
        "pkRecID": "1",
        "clientid": props.clientID,
        "type": "",
        "settings": '{}',
        "tagProfile": ""
    });


    const [tags, setTags] = useState([]);
    const [tagProfiles, setTagProfiles] = useState(false);
    const [open, setopen] = useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [selectedTAG, setSelectedTAG] = React.useState({});
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [copySuccess, setCopySuccess] = useState("");
    const theme = useTheme();

    const handleClose = () => {
        setopen(false);
        setCopySuccessShow(false);
    }

    const openNew = () => {
        setopen(true);
    }

    async function fetchData() {

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        const res = await fetch(appVars.api_url + "/tag_profiles", requestOptions);
        res.json()
            .then((response) => {
                var k = _.sortBy(response, 'name');
                setTagProfiles(k);
            })
            .catch(error => console.log('error', error));

        const res2 = await fetch(appVars.api_url + "/tags/" + props.clientID, requestOptions);
        res2.json()
            .then((response) => {
                var k = _.sortBy(response, 'type');
                setTags(k);
               // console.log(k);
            })
            .catch(error => console.log('error', error));
    }


    const MyToolTip = function MyComponent(props) {
        var e = props;
       // console.log(e);
        //  Spread the props to the underlying DOM element.
        return <div {...props}>
            {Object.entries(JSON.parse(e.settings)).map((entry2) => (
                <Typography variant="body1" color="textSecondary" component="p">{entry2[0].toUpperCase()}: <Link className='copy' data-clipboard-text={e.type + ' ' + entry2[0] + ' ' + entry2[1] + ' Copied!'}  >{entry2[1].length > 25 ? entry2[1].substring(0, 25) + '...' : entry2[1]}</Link></Typography>
            ))}
        </div>
    };



    useEffect(() => {

        if (tagProfiles === false) {
            fetchData();
        }
        var clipboard = new ClipboardJS(".copy");
        clipboard.on("success", function (e) {
            setCopySuccess(e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    });

    const TagTypes = ({ field, form, ...props }) => {

        //console.log(k);
        return <TextField select size="small" {...field} {...props}
            onChange={(e) => {
                tag.tagProfile = e.target.value;
                tag.type = e.currentTarget.dataset.form;
                tag.settings = JSON.parse(e.currentTarget.dataset.settings);
                setTag(tag);
                //console.log(e.currentTarget.dataset.settings);
                //document.getElementById('name').value = e.currentTarget.dataset.form;
                //document.getElementById('logoHolder').src = appVars.api_url + '/imageFiles/' + e.target.value + '.png';
                openNew();
            }
            }
        >
            {tagProfiles.map((entry) => (
                <MenuItem key={entry.pkRecID} data-settings={entry.params} data-form={entry.name} value={entry.pkRecID}>{entry.name}</MenuItem>
            ))}
        </TextField>;
    };


    return (
        <div style={{ marginTop: '.5em' }}>
            <Grid container xl={12} spacing={2} fle >

                <Grid item xl={2} md={12} xs={12}>
                    {(tagProfiles !== false ? <TagTypes InputLabelProps={{ shrink: true }} label="Add New Tag" variant="outlined" color="primary" margin="none" size="small" fullWidth /> : <CircularProgress></CircularProgress>)}
                </Grid>

                <Grid item xl={2} md={3} xs={12} >
               </Grid>

                <Grid item xl={2} md={3} xs={12}>
                   
                </Grid>

                <Grid item xl={2} md={3} xs={12}>
                   

                </Grid>

                <Grid item xl={2} md={3} xs={12}>
              
                </Grid>

                <Grid item xl={2} md={3} xs={12}>
               
                </Grid>

            </Grid>
            <Divider style={{ margin: "15px auto" }}></Divider>


            {(tags ?


                <Grid container spacing={1}>

                    <Grid item md={12}>
                        <MaterialTable
                            data={tags}
                            options={{
                                rowStyle: { fontSize: '13px', padding: 0, margin: 0 },
                                headerStyle: { fontSize: '13px' },
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 15, tags.length],
                                showTitle: false

                            }}
                            columns={[
                                {
                                    title: "Entry Date", field: "", render: e =>
                                        <img style={{ width: '100%', maxWidth: 100 }} src={appVars.api_url + '/imageFiles/' + e.tagProfile + '.png'} aria-label="recipe" variant="square" />


                                },
                                {
                                    cellStyle: { fontSize: '13px' },
                                    title: "Tag Name", field: "createdAt", type: "datetime", render: e =>
                                        <div style={{ display: 'flex', alignItems: 'center' }}><Tooltip placement="top-start" arrow={true} title={MyToolTip(e)}><HelpOutline style={{ fontSize: 15, marginRight: theme.spacing(1) }} /></Tooltip> <Typography> {e.type}</Typography></div>
                                },
                                {
                                    title: "Entry Date", field: "createdAt", type: "datetime", render: e =>
                                        <ButtonGroup variant="outlined">
                                            <Button size="small" color="secondary" target='_BLANK' variant="outlined"
                                                onClick={(es) => {
                                                    setSelectedTAG(e);
                                                    setOpenDelete(true);
                                                }}
                                            ><Delete></Delete>
                                            </Button>
                                            {(e.tagProfile === "35d4eef1-71d1-11ee-bfcd-16725369f13b" ? <Button size="small" variant="outlined" color="primary" href={appVars.api_url + '/generate-gtm-container/g4update/' + e.pkRecId}><CloudDownload title="Download GA4 Update"></CloudDownload></Button> : '')}
                                            {(e.tagProfile !== "6d20c78b-71cf-11ee-bfcd-16725369f13b1" ? <Button size="small" variant="outlined" color="primary" href={appVars.api_url + '/generate-gtm-tag/' + e.tagProfile + '/' + e.clientid} target='_BLANK'><CloudDownload></CloudDownload></Button> : '')}
                                            {(e.tagProfile === "6d20c78b-71cf-11ee-bfcd-16725369f13b" ? <Button size="small" id="fbcopy" color="default" href={'https://business.facebook.com/events_manager2/list/pixel/' + JSON.parse(e.settings).id + '/overview'} title={'Open Pixel Overview for ' + JSON.parse(e.settings).id} target="_BLANK" ><OpenInNew></OpenInNew></Button> : '')}


                                        </ButtonGroup>
                                },

                            ]}
                        />
                    </Grid>
                    <Grid item xl={6} style={{ display: 'none' }}>


                        {tags.map((entry) => (
                            <Grid item md={12} sm={12} xs={12}>
                                <Card variant="outlined" >
                                    <CardHeader
                                        avatar={
                                            <Avatar src={appVars.api_url + '/imageFiles/' + entry.tagProfile + '-icon.png'} aria-label="recipe" variant="rounded">

                                            </Avatar>
                                        }
                                        title={entry.type}
                                        style={{ background: theme.palette.primary.main }}
                                    //  subheader="September 14, 2016"
                                    />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <CardContent style={{ minHeight: 100, maxHeight: 120 }}>
                                            {Object.entries(JSON.parse(entry.settings)).map((entry2) => (
                                                <Typography variant="body2" color="textSecondary" component="p">{entry2[0]}: <Link className='copy' data-clipboard-text={entry.type + ' ' + entry2[0] + ' ' + entry2[1] + ' Copied!'}  >{entry2[1].length > 25 ? entry2[1].substring(0, 25) + '...' : entry2[1]}</Link></Typography>
                                            ))}
                                        </CardContent>
                                    </div>
                                    <CardActions style={{ background: lightenHexColor("#f7f7f7", 100) }}>

                                        {(entry.tagProfile === "35d4eef1-71d1-11ee-bfcd-16725369f13b" ? <Button size="small" color="primary" href={appVars.api_url + '/generate-gtm-container/g4update/' + entry.pkRecId}><CloudDownload title="Download GA4 Update"></CloudDownload></Button> : '')}
                                        {(entry.tagProfile !== "6d20c78b-71cf-11ee-bfcd-16725369f13b1" ? <Button size="small" color="primary" href={appVars.api_url + '/generate-gtm-tag/' + entry.tagProfile + '/' + entry.clientid} target='_BLANK' variant="contained" ><CloudDownload></CloudDownload></Button> : '')}
                                        {(entry.tagProfile === "6d20c78b-71cf-11ee-bfcd-16725369f13b" ? <Button size="small" id="fbcopy" color="primary" href={'https://business.facebook.com/events_manager2/list/pixel/' + entry.settings.id + '/overview'} title={'Open Pixel Overview for ' + entry.settings.id} target="_BLANK" ><OpenInNew></OpenInNew></Button> : '')}
                                        <Button size="small" color="primary" href={appVars.api_url + '/generate-gtm-tag/' + entry.tagProfile + '/' + entry.clientid} target='_BLANK' variant="contained" ><Edit></Edit></Button>
                                        <div style={{ flexGrow: 1 }} />
                                        <Button size="small" color="secondary" target='_BLANK' variant="outlined"
                                            onClick={(e) => {
                                                setSelectedTAG(entry);
                                                setOpenDelete(true);
                                            }}
                                        ><Delete></Delete></Button>

                                    </CardActions>

                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                : <CircularProgress></CircularProgress>)
            }

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>


            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add New Tag
                    <img src={appVars.api_url + '/imageFiles/' + tag.tagProfile + '.png'} style={{ maxWidth: 100, float: 'right' }} id="logoHolder" alt='' />
                </DialogTitle>
                <DialogContent style={{ padding: "30px", borderBottom: "1px solid #cfcfcf" }}>
                    <DialogContentText>
                        Select Tag Type below to set up a new tag for TAG manager
                    </DialogContentText>

                    <Grid container spacing={2}>

                        <Grid item xl={12} md={12}>
                            <TextField
                                id="tagProfile"
                                type="hidden"
                            />
                            <TextField

                                margin="dense"
                                id="name"
                                label="Name Tag"
                                type="text"
                                disabled={true}
                                value={tag.type}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    tag.type = e.target.value;
                                    setTag(tag);
                                    console.log(tag);
                                }}
                            />

                        </Grid>



                        {Object.entries(tag.settings).map((entry2) => (

                            <Grid item xl={6} md={6}>

                                <TextField

                                    margin="dense"
                                    id={entry2[0]}
                                    label={entry2[0].toUpperCase()}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        tag.settings[entry2[0]] = e.target.value;
                                        setTag(tag);
                                        console.log(tag);
                                    }}
                                />

                            </Grid>

                        ))}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" startIcon={<Add></Add>} onClick={async (e) => {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                            method: 'POST',
                            redirect: 'follow',
                            headers: myHeaders,
                            body: JSON.stringify(tag)
                        };

                        const res = await fetch(appVars.api_url + "/tags/a", requestOptions);
                        res.json()
                            .then((response) => {
                                fetchData();
                                handleClose();
                            })
                            .then(result => console.log(result))
                            .catch(error => console.log('error', error));



                    }}>
                        Add New
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openDelete}

            >
                <DialogTitle id="confirmation-dialog-title">Do you want to delete tag?</DialogTitle>
                <DialogContent dividers>
                    <img style={{ maxWidth: 100 }} src={appVars.api_url + '/imageFiles/' + selectedTAG.tagProfile + '.png'} alt='' /><br></br>

                    <Typography variant='body'>{selectedTAG.type}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => {
                        setOpenDelete(false);
                    }} autoFocus color="primary" variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={(e) => {
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "pkRecID": selectedTAG.pkRecID
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };
                        fetch(appVars.api_url + "/tags/d", requestOptions)
                            .then(response => response.text())
                            .then(result => setOpenDelete(false))
                            .then(result2 => fetchData())
                            .catch(error => console.log('error', error));
                    }} variant="contained" color="secondary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>



        </div>
    );
};

Tags.propTypes = {

};

export default Tags;
