import React, { useState, useEffect } from "react";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { NumericFormat } from 'react-number-format';
import { makeStyles } from "@material-ui/core/styles";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    DialogActions,
    Typography,
    Button,
    Snackbar,
    Avatar,
    Divider,
    Paper
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import ClipboardJS from "clipboard";
import AdWords from '../../images/adwords.png';
import appVars from '../../appVars.json';
import { BsEye, BsMeta, BsBing, BsDisplay } from "react-icons/bs";
import { FaGoogle } from "react-icons/fa";
import _ from 'lodash';
import moment from 'moment';
import CookieReader from "../cookie-reader";
import { useLocation } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    table: {
        marginTop: "1em",
    },
    title: {
        borderBottom: "1px solid #000",
    },
    pos: {
        marginBottom: 12,
    },
    icon: {
        marginRight: ".25em",
        fontSize: "18px"
    },
    paper: {
        padding: theme.spacing(1),
        background: theme.palette.primary.light,
    }
}));

var btn = new ClipboardJS(".copy");


const Registrations = (props) => {
    const location = useLocation();
    var id = location.pathname.split("/")[2];
    const searchParams = new URLSearchParams(location.search);
    const [data, setData] = useState(props);
    const classes = useStyles();
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [users, setUsers] = useState(false);
    const [pageSize, setPageSize] = useState(100);
    const [user, setUser] = useState([]);
    const [apiStats, setApiStats] = useState({
        "client_id": "",
        "total_records": 1967,
        "name_percentage": 0,
        "city_percentage": 0,
        "state_percentage": 0,
        "zip_percentage": 0,
        "phone_percentage": 0,
        "email_percentage": 0,
        "fb_percentage": 0,
        "fbc_percentage": 0,
        "ga_percentage": 0,
        "sa_percentage": 0,
        "adwords_percentage": 0,
        "bing_percentage": 0
    });
    const [open, setOpen] = useState(false);
    const [owner, setOwner] = useState('Not Set');
    const [message, setMessage] = useState({ show: false, message: '' });
    const [loading, setLoading] = useState(true);
    const [copySuccess, setCopySuccess] = useState("");


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    async function fetchData() {
        console.log(props);
        const res = await fetch(appVars.api_url + "/contact/" + id + "/" + pageSize);
        res
            .json()
            .then((res) => {

                var d = _.filter(res, function (o) {
                    var e = moment(o.createdAt).format("YYYY-MM-DD");
                    //  console.log(e);
                    if (e >= data.sdate && e <= data.edate) {
                        return o.createdAt;
                    }
                });
                  console.log(d);
                setUsers(d);
                setLoading(false);
            })
            .catch((err) => setErrors(true));



        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "cid": id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };




        const res2 = await fetch(appVars.api_url + "/apistats", requestOptions)
        res2
            .json()
            .then((res2) => {
                   console.log(res2);
                   if(res2){
                     setApiStats(res2);
                   }
               
                setLoading(false);
            })
            .catch((err) => setErrors(true));

    }

    useEffect(() => {

        //  if (user.length === 0) {
        fetchData();
        //  }

        setData(props);

        var clipboard = new ClipboardJS(".copy");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    }, [props]);
    return (
        <div className={style}>
            <div className={classes.table}>
                {(apiStats ?
                    <Grid container spacing={1}>

                        <Grid item sm={2}>
                            <Paper className={classes.paper}>
                                <Typography align="center" color="textSecondary"  gutterBottom variant="h6">Meta Users:</Typography>
                                <Typography align="center" color="textSecondary" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.fb_percentage} /></Typography>
                            </Paper>
                        </Grid>
                        <Grid item sm={2}>
                            <Paper className={classes.paper}>
                                <Typography align="center" color="textSecondary" gutterBottom variant="h6">Meta Clickers:</Typography>
                                <Typography align="center" color="textSecondary" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.fbc_percentage} /></Typography>
                            </Paper>
                        </Grid>
                        <Grid item sm={2}>
                            <Paper className={classes.paper}>
                                <Typography align="center" color="textSecondary"  gutterBottom variant="h6">Google Users:</Typography>
                                <Typography align="center" color="textSecondary" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.ga_percentage} /></Typography>
                            </Paper>
                        </Grid>
                        <Grid item sm={2}>
                            <Paper className={classes.paper}>
                                <Typography align="center" color="textSecondary" gutterBottom variant="h6">Google Clickers:</Typography>
                                <Typography align="center" color="textSecondary" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.adwords_percentage}/></Typography>
                            </Paper>
                        </Grid>
                        <Grid item sm={2}>
                            <Paper className={classes.paper}>
                                <Typography align="center" color="textSecondary" gutterBottom variant="h6">Stackadapt Users:</Typography>
                                <Typography align="center" color="textSecondary" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.sa_percentage} /></Typography>
                            </Paper>
                        </Grid>
                        <Grid item sm={2}>
                            <Paper className={classes.paper}>
                                <Typography align="center" color="textSecondary" gutterBottom variant="h6">Bing Users:</Typography>
                                <Typography align="center" color="textSecondary" gutterBottom variant="body2"><NumericFormat displayType="text" decimalScale={2} suffix="%" value={apiStats.bing_percentage} /></Typography>
                            </Paper>
                        </Grid>

                        <Grid item sm={12}>

                            {(users ?
                                <MaterialTable
                                    key={users.length}
                                    isLoading={loading}
                                    columns={[
                                        {
                                            title: "Entry Date", field: "createdAt", type: "datetime", render: e =>
                                                <Typography style={{ minWidth: 175 }}>{moment(e.createdAt).format("MM-DD-YYYY hh:mm:s")}</Typography>
                                        },
                                        { title: "pkRecID", field: "pkRecId", hidden: true, searchable: true },
                                              { title: "First Name (" + (apiStats.name_percentage) + "%)", field: "first" },
                                              { title: "Last Name (" + apiStats.name_percentage + "%)", field: "last" },
                                              { title: "Email Address", field: "email" },
                                              { title: "City (" + apiStats.city_percentage + "%)", field: "city" },
                                              { title: "State (" + apiStats.state_percentage + "%)", field: "state" },
                                              { title: "Zip (" + apiStats.zip_percentage + "%)", field: "zip" },
                                              { title: "Phone (" + apiStats.phone_percentage + "%)", field: "phone" },
                                        { title: "$", field: "revenue" },
                                        { hidden: true, title: "Source", field: "source" },
                                        {
                                            title: "User", field: "url", render: e => {
                                                var k = JSON.parse(e.cookie);
                                                if (k !== null) {
                                                    // console.log(k);
                                                    return <div style={{ minWidth: 125 }}>
                                                        {(k._fbp !== "" && k._fbp !== undefined ? <BsMeta className={classes.icon} title={(k._fbc !== '' && k._fbc !== undefined ? 'Meta Conversion' : 'Meta User')} style={{ color: (k._fbc !== '' && k._fbc !== undefined ? 'green' : '') }}></BsMeta> : '')}
                                                        {(k._ga !== '' && k._ga !== undefined ? <FaGoogle className={classes.icon}></FaGoogle> : '')}{(k._gclid !== '' && k._gclid !== undefined ? <AdWords></AdWords> : '')}
                                                        {(k._uetsid !== "" && k._uetsid !== undefined ? <BsBing className={classes.icon} style={(k._uetsid !== '' && k._uetsid !== undefined ? {} : { filter: 'grayscale(100%)' })} /> : '')}
                                                        {(k['sa-user-id'] !== "" && k['sa-user-id'] !== undefined ? <BsDisplay className={classes.icon} style={(k['sa-user-id'] !== '' && k['sa-user-id'] !== undefined ? {} : { filter: 'grayscale(100%)' })} /> : '')}

                                                    </div>
                                                }
                                            }
                                        },
                                        {
                                            title: "Entry Date", field: "createdAt", type: "datetime", render: rowData =>
                                                <Button variant="outlined" color="secondary" startIcon={<BsEye></BsEye>} onClick={(e) => {
                                                    setUser(rowData);
                                                    setOpen(true);
                                                }}>View</Button>
                                        },
                                        {
                                            title: "Location", field: "url", render: e =>
                                                <Typography><Link target="_blank" href={e.url}>Location</Link></Typography>
                                        }


                                    ]}
                                    data={users}

                                    options={{
                                        rowStyle: { fontSize: '13px', padding: 0, margin: 0 },
                                        headerStyle: { fontSize: '13px' },
                                        grouping: true,
                                        exportButton: true,
                                        exportAllData: true,
                                        exportFileName: moment(new Date()).format("YYYYMMDD.hhmmss"),
                                        pageSize: 10,
                                        pageSizeOptions: [10, 25, 50, 75, 100, 200, 500, 1000],
                                        showTitle: false

                                    }}
                                />

                                : '')}

                        </Grid>






                        <Grid item sm={2}>
                            <Paper style={{ display: "none" }} className={classes.paper} variant="outlined">
                                <Typography gutterBottom variant="body2">Total Records: {apiStats.total_records}</Typography>
                                <Typography gutterBottom variant="body2">Name: {(apiStats.name_percentage)}</Typography>
                                <Typography gutterBottom variant="body2">City: {apiStats.name_percentage}</Typography>
                                <Typography gutterBottom variant="body2">State: {apiStats.name_percentage}</Typography>
                                <Typography gutterBottom variant="body2">Phone: {apiStats.name_percentage}</Typography>
                                <Typography gutterBottom variant="body2">Meta User: {apiStats.fb_percentage}</Typography>
                                <Typography gutterBottom variant="body2">Meta Clicker: {apiStats.fbc_percentage}</Typography>
                                <Typography gutterBottom variant="body2">Google User: {apiStats.ga_percentage}</Typography>
                                <Typography gutterBottom variant="body2">Adwords Clicker: {apiStats.adwords_percentage}</Typography>
                                <Typography gutterBottom variant="body2">Bing User: {apiStats.bing_percentage}</Typography>
                                <Typography gutterBottom variant="body2">StackAdapt User: {apiStats.sa_percentage}</Typography>
                            </Paper>

                        </Grid>

                    </Grid>
                    : '')}

            </div>




            <Dialog
                maxWidth="sm"
                aria-labelledby="confirmation-dialog-title"
                open={open}
            >
                {
                    <DialogTitle id="confirmation-dialog-title"></DialogTitle>
                }
                <DialogContent dividers>
                    <Grid container md={12} spacing={3}>
                        <Grid item md={1} >
                            <Avatar></Avatar>
                        </Grid>




                        <Grid item md={12}>
                            <Divider></Divider>
                            <Typography variant="h5">Cookies</Typography>
                            <CookieReader cookie={user.cookie}></CookieReader>

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="outlined" color="primary" onClick={(e) => {
                        setOpen(false);
                    }} autoFocus >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>





            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>

    );
};

Registrations.propTypes = {

};

export default Registrations;
