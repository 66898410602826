import React from 'react';
import { style } from './index.scss';
import appVars from '../../appVars.json';
import themeFile from '../../theme.json';








const htmlContent = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html dir="ltr" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="en">
<head>
<meta charset="UTF-8">
<meta content="width=device-width, initial-scale=1" name="viewport">
<meta name="x-apple-disable-message-reformatting">
<meta http-equiv="X-UA-Compatible" content="IE=edge">
<meta content="telephone=no" name="format-detection">
<title>Password Reset</title>
<!--[if (mso 16)]><style type="text/css"> a {text-decoration: none;} </style><![endif]-->
<!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
<!--[if gte mso 9]><xml> <o:OfficeDocumentSettings> <o:AllowPNG></o:AllowPNG> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings> </xml>
<![endif]-->
<!--[if !mso]><!-- -->
<link href="https://fonts.googleapis.com/css2?family=Orbitron&display=swap" rel="stylesheet">
<!--<![endif]-->
<style type="text/css">
#outlook a { padding:0;}.es-button { mso-style-priority:100!important; text-decoration:none!important;}a[x-apple-data-detectors] { color:inherit!important; text-decoration:none!important; font-size:inherit!important; font-family:inherit!important; font-weight:inherit!important; line-height:inherit!important;}.es-desk-hidden { display:none; float:left; overflow:hidden; width:0; max-height:0; line-height:0; mso-hide:all;}.es-button-border:hover a.es-button, .es-button-border:hover button.es-button { background:#58dfec!important;} .es-button-border:hover { border-color:#26C6DA #26C6DA #26C6DA #26C6DA!important; background:#58dfec!important; border-style:solid solid solid solid!important;}
@media only screen and (max-width:600px) {p, ul li, ol li, a { line-height:150%!important } h1, h2, h3, h1 a, h2 a, h3 a { line-height:120% } h1 { font-size:30px!important; text-align:center } h2 { font-size:24px!important; text-align:left } h3 { font-size:20px!important; text-align:left } .es-header-body h1 a, .es-content-body h1 a, .es-footer-body h1 a { font-size:30px!important; text-align:center } .es-header-body h2 a, .es-content-body h2 a, .es-footer-body h2 a { font-size:24px!important; text-align:left } .es-header-body h3 a, .es-content-body h3 a, .es-footer-body h3 a { font-size:20px!important; text-align:left } .es-menu td a { font-size:14px!important } .es-header-body p, .es-header-body ul li, .es-header-body ol li, .es-header-body a { font-size:14px!important } .es-content-body p, .es-content-body ul li, .es-content-body ol li, .es-content-body a { font-size:14px!important }
 .es-footer-body p, .es-footer-body ul li, .es-footer-body ol li, .es-footer-body a { font-size:14px!important } .es-infoblock p, .es-infoblock ul li, .es-infoblock ol li, .es-infoblock a { font-size:12px!important } *[class="gmail-fix"] { display:none!important } .es-m-txt-c, .es-m-txt-c h1, .es-m-txt-c h2, .es-m-txt-c h3 { text-align:center!important } .es-m-txt-r, .es-m-txt-r h1, .es-m-txt-r h2, .es-m-txt-r h3 { text-align:right!important } .es-m-txt-l, .es-m-txt-l h1, .es-m-txt-l h2, .es-m-txt-l h3 { text-align:left!important } .es-m-txt-r img, .es-m-txt-c img, .es-m-txt-l img { display:inline!important } .es-button-border { display:inline-block!important } a.es-button, button.es-button { font-size:18px!important; display:inline-block!important } .es-adaptive table, .es-left, .es-right { width:100%!important }
 .es-content table, .es-header table, .es-footer table, .es-content, .es-footer, .es-header { width:100%!important; max-width:600px!important } .es-adapt-td { display:block!important; width:100%!important } .adapt-img { width:100%!important; height:auto!important } .es-m-p0 { padding:0px!important } .es-m-p0r { padding-right:0px!important } .es-m-p0l { padding-left:0px!important } .es-m-p0t { padding-top:0px!important } .es-m-p0b { padding-bottom:0!important } .es-m-p20b { padding-bottom:20px!important } .es-mobile-hidden, .es-hidden { display:none!important } tr.es-desk-hidden, td.es-desk-hidden, table.es-desk-hidden { width:auto!important; overflow:visible!important; float:none!important; max-height:inherit!important; line-height:inherit!important } tr.es-desk-hidden { display:table-row!important } table.es-desk-hidden { display:table!important } td.es-desk-menu-hidden { display:table-cell!important }
 .es-menu td { width:1%!important } table.es-table-not-adapt, .esd-block-html table { width:auto!important } table.es-social { display:inline-block!important } table.es-social td { display:inline-block!important } .es-desk-hidden { display:table-row!important; width:auto!important; overflow:visible!important; max-height:inherit!important } }@media screen and (max-width:384px) {.mail-message-content { width:414px!important } }
</style>
</head>
<body style="width:100%;font-family:arial, 'helvetica neue', helvetica, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0">
<div dir="ltr" class="es-wrapper-color" lang="en" style="background-color:#07023C">
  <!--[if gte mso 9]><v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t"> <v:fill type="tile" color="#07023c"></v:fill> </v:background><![endif]-->
  <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;background-color:#07023C">
    <tr>
      <td valign="top" style="padding:0;Margin:0">
        <table class="es-content" cellspacing="0" cellpadding="0" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
          <tr>
            <td align="center" style="padding:0;Margin:0">
              <table class="es-content-body" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#ffffff;background-repeat:no-repeat;width:600px;background-image:url(https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_0Ia.png);background-position:center center" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center" background="https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_0Ia.png" role="none">
                <tr>
                  <td align="left" style="Margin:0;padding-bottom:10px;padding-top:20px;padding-left:20px;padding-right:20px">
                    <table cellpadding="0" cellspacing="0" width="100%" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                      <tr>
                        <td class="es-m-p0r" valign="top" align="center" style="padding:0;Margin:0;width:560px">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td align="center" style="padding:0;Margin:0;font-size:0px">
                                <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#26C6DA;font-size:14px">
                                  <img src="https://efbutg.stripocdn.email/content/guids/CABINET_b78baa9630348146026decca4228d0f23b4c6a4aca4c497342c6d6a709e175ae/images/smallinnovationslogo.png" alt="Logo" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic" title="Logo" height="100">
                                </a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="Margin:0;padding-left:20px;padding-right:20px;padding-top:30px;padding-bottom:30px">
                    <table width="100%" cellspacing="0" cellpadding="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                      <tr>
                        <td class="es-m-p0r es-m-p20b" valign="top" align="center" style="padding:0;Margin:0;width:560px">
                          <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td align="center" style="padding:0;Margin:0">
                                <h1 style="Margin:0;line-height:53px;mso-line-height-rule:exactly;font-family:Orbitron, sans-serif;font-size:44px;font-style:normal;font-weight:bold;color:#10054D">&nbsp;We got a request to reset your&nbsp;password</h1>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="padding:0;Margin:0;padding-bottom:10px;padding-top:15px;font-size:0px">
                                <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#26C6DA;font-size:14px">
                                  <img class="adapt-img" src="https://efbutg.stripocdn.email/content/guids/CABINET_dee64413d6f071746857ca8c0f13d696/images/852converted_1x3.png" alt style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic" height="300">
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px">
                                <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">&nbsp;Forgot your password? No problem - it happens to everyone!</p>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="padding:0;Margin:0;padding-top:15px;padding-bottom:15px">
                                <span class="es-button-border" style="border-style:solid;border-color:#26C6DA;background:#26C6DA;border-width:4px;display:inline-block;border-radius:10px;width:auto">
                                  <a href="https://viewstripo.email" class="es-button" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:20px;padding:10px 25px 10px 30px;display:inline-block;background:#26C6DA;border-radius:10px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:24px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #26C6DA"> Reset Your Password</a>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px">
                                <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">If you ignore this message, your password won't be changed.</p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table cellpadding="0" cellspacing="0" class="es-content" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%">
          <tr>
            <td align="center" style="padding:0;Margin:0">
              <table bgcolor="#10054D" class="es-content-body" align="center" cellpadding="0" cellspacing="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#10054d;width:600px" role="none">
                <tr>
                  <td align="left" background="https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_sSY.png" style="Margin:0;padding-left:20px;padding-right:20px;padding-top:35px;padding-bottom:35px;background-image:url(https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_sSY.png);background-repeat:no-repeat;background-position:left center">
                    <!--[if mso]><table style="width:560px" cellpadding="0" cellspacing="0"><tr><td style="width:69px" valign="top"><![endif]-->
                    <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                      <tr>
                        <td class="es-m-p20b" align="left" style="padding:0;Margin:0;width:69px">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td align="center" class="es-m-txt-l" style="padding:0;Margin:0;font-size:0px">
                                <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#26C6DA;font-size:14px">
                                  <img src="https://efbutg.stripocdn.email/content/guids/CABINET_dee64413d6f071746857ca8c0f13d696/images/group_118_lFL.png" alt style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic" width="69">
                                </a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <!--[if mso]></td><td style="width:20px"></td>
<td style="width:471px" valign="top"><![endif]-->
                    <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                      <tr>
                        <td align="left" style="padding:0;Margin:0;width:471px">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td align="left" style="padding:0;Margin:0">
                                <h3 style="Margin:0;line-height:34px;mso-line-height-rule:exactly;font-family:Orbitron, sans-serif;font-size:28px;font-style:normal;font-weight:bold;color:#ffffff"><b>Real people. Here to help.</b></h3>
                              </td>
                            </tr>
                            <tr>
                              <td align="left" style="padding:0;Margin:0;padding-bottom:5px;padding-top:10px">
                                <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#ffffff;font-size:14px">Have a question? Give us a call at&nbsp;<strong><a href="tel:(000)1234567899" target="_blank" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#26C6DA;font-size:14px">(000) 1234 5678 99</a></strong>&nbsp;to chat with a Customer Success representative.</p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <!--[if mso]></td></tr></table><![endif]-->
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table cellpadding="0" cellspacing="0" class="es-footer" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top">
          <tr>
            <td align="center" style="padding:0;Margin:0">
              <table bgcolor="#ffffff" class="es-footer-body" align="center" cellpadding="0" cellspacing="0" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px">
                <tr>
                  <td align="left" style="padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-top:30px">
                    <table cellpadding="0" cellspacing="0" width="100%" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                      <tr>
                        <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td align="center" style="padding:0;Margin:0">
                                <h2 style="Margin:0;line-height:43px;mso-line-height-rule:exactly;font-family:Orbitron, sans-serif;font-size:36px;font-style:normal;font-weight:bold;color:#10054D">Is this newsletter helpful?</h2>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td class="esdev-adapt-off" align="left" style="Margin:0;padding-bottom:20px;padding-left:20px;padding-right:20px;padding-top:30px">
                    <table cellpadding="0" cellspacing="0" class="esdev-mso-table" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;width:560px">
                      <tr>
                        <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                          <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                            <tr class="es-mobile-hidden">
                              <td align="left" style="padding:0;Margin:0;width:63px">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" height="40" style="padding:0;Margin:0"></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="padding:0;Margin:0;width:20px"></td>
                        <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                          <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                            <tr>
                              <td align="left" style="padding:0;Margin:0;width:63px">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                      <span class="es-button-border" style="border-style:solid;border-color:#26C6DA;background:#26C6DA;border-width:4px;display:inline-block;border-radius:10px;width:auto">
                                        <a href="https://viewstripo.email" class="es-button es-button-1637938355061" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:16px;padding:5px 15px;display:inline-block;background:#26C6DA;border-radius:10px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:19px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #26C6DA">1</a>
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="padding:0;Margin:0;width:20px"></td>
                        <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                          <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                            <tr>
                              <td align="left" style="padding:0;Margin:0;width:63px">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                      <span class="es-button-border" style="border-style:solid;border-color:#26C6DA;background:#26C6DA;border-width:4px;display:inline-block;border-radius:10px;width:auto">
                                        <a href="https://viewstripo.email" class="es-button es-button-1637938414709" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:16px;padding:5px 15px;display:inline-block;background:#26C6DA;border-radius:10px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:19px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #26C6DA">2</a>
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="padding:0;Margin:0;width:20px"></td>
                        <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                          <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                            <tr>
                              <td align="left" style="padding:0;Margin:0;width:63px">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                      <span class="es-button-border" style="border-style:solid;border-color:#26C6DA;background:#26C6DA;border-width:4px;display:inline-block;border-radius:10px;width:auto">
                                        <a href="https://viewstripo.email" class="es-button es-button-1637938422665" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:16px;padding:5px 15px;display:inline-block;background:#26C6DA;border-radius:10px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:19px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #26C6DA">3</a>
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="padding:0;Margin:0;width:20px"></td>
                        <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                          <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                            <tr>
                              <td align="left" style="padding:0;Margin:0;width:63px">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                      <span class="es-button-border" style="border-style:solid;border-color:#26C6DA;background:#26C6DA;border-width:4px;display:inline-block;border-radius:10px;width:auto">
                                        <a href="https://viewstripo.email" class="es-button es-button-1637938430832" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:16px;padding:5px 15px;display:inline-block;background:#26C6DA;border-radius:10px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:19px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #26C6DA">4</a>
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="padding:0;Margin:0;width:20px"></td>
                        <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                          <table cellpadding="0" cellspacing="0" class="es-left" align="left" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left">
                            <tr>
                              <td align="left" style="padding:0;Margin:0;width:63px">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" style="padding:0;Margin:0">
                                      <span class="es-button-border" style="border-style:solid;border-color:#26C6DA;background:#26C6DA;border-width:4px;display:inline-block;border-radius:10px;width:auto">
                                        <a href="https://viewstripo.email" class="es-button es-button-1637938437176" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:16px;padding:5px 15px;display:inline-block;background:#26C6DA;border-radius:10px;font-family:arial, 'helvetica neue', helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:19px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #26C6DA">5</a>
                                      </span>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td style="padding:0;Margin:0;width:20px"></td>
                        <td class="esdev-mso-td" valign="top" style="padding:0;Margin:0">
                          <table cellpadding="0" cellspacing="0" class="es-right" align="right" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right">
                            <tr class="es-mobile-hidden">
                              <td align="left" style="padding:0;Margin:0;width:62px">
                                <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" height="40" style="padding:0;Margin:0"></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="Margin:0;padding-top:10px;padding-left:20px;padding-right:20px;padding-bottom:30px">
                    <table cellpadding="0" cellspacing="0" width="100%" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                      <tr>
                        <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td align="center" style="padding:0;Margin:0">
                                <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px">1 —&nbsp;Not at all&nbsp;helpful&nbsp;😟<br>5&nbsp;—&nbsp;Extremely&nbsp;helpful&nbsp;😊</p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <table cellpadding="0" cellspacing="0" class="es-footer" align="center" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top">
          <tr>
            <td align="center" style="padding:0;Margin:0">
              <table class="es-footer-body" align="center" cellpadding="0" cellspacing="0" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px" role="none">
                <tr>
                  <td align="left" style="Margin:0;padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px">
                    <table cellpadding="0" cellspacing="0" width="100%" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                      <tr>
                        <td align="left" style="padding:0;Margin:0;width:560px">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td style="padding:0;Margin:0">
                                <table cellpadding="0" cellspacing="0" width="100%" class="es-menu" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr class="links">
                                    <td align="center" valign="top" width="25%" style="Margin:0;padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;border:0">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:none;display:block;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#ffffff;font-size:14px">About us</a>
                                    </td>
                                    <td align="center" valign="top" width="25%" style="Margin:0;padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;border:0">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:none;display:block;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#ffffff;font-size:14px">News</a>
                                    </td>
                                    <td align="center" valign="top" width="25%" style="Margin:0;padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;border:0">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:none;display:block;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#ffffff;font-size:14px">Career</a>
                                    </td>
                                    <td align="center" valign="top" width="25%" style="Margin:0;padding-left:5px;padding-right:5px;padding-top:10px;padding-bottom:10px;border:0">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:none;display:block;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#ffffff;font-size:14px">The shops</a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px;font-size:0">
                                <table cellpadding="0" cellspacing="0" class="es-table-not-adapt es-social" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                                  <tr>
                                    <td align="center" valign="top" style="padding:0;Margin:0;padding-right:20px">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#FFFFFF;font-size:14px">
                                        <img title="Facebook" src="https://efbutg.stripocdn.email/content/assets/img/social-icons/square-colored/facebook-square-colored.png" alt="Fb" width="32" height="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic">
                                      </a>
                                    </td>
                                    <td align="center" valign="top" style="padding:0;Margin:0;padding-right:20px">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#FFFFFF;font-size:14px">
                                        <img title="Twitter" src="https://efbutg.stripocdn.email/content/assets/img/social-icons/square-colored/twitter-square-colored.png" alt="Tw" width="32" height="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic">
                                      </a>
                                    </td>
                                    <td align="center" valign="top" style="padding:0;Margin:0;padding-right:20px">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#FFFFFF;font-size:14px">
                                        <img title="Instagram" src="https://efbutg.stripocdn.email/content/assets/img/social-icons/square-colored/instagram-square-colored.png" alt="Inst" width="32" height="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic">
                                      </a>
                                    </td>
                                    <td align="center" valign="top" style="padding:0;Margin:0">
                                      <a target="_blank" href="https://viewstripo.email" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#FFFFFF;font-size:14px">
                                        <img title="Youtube" src="https://efbutg.stripocdn.email/content/assets/img/social-icons/square-colored/youtube-square-colored.png" alt="Yt" width="32" height="32" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic">
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="padding:0;Margin:0;padding-top:10px;padding-bottom:10px">
                                <p style="Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;line-height:18px;color:#ffffff;font-size:12px">You are receiving this email because you have visited our site or asked us about the regular newsletter. Make sure our messages get to your Inbox (and not your bulk or junk folders).<br><a target="_blank" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#FFFFFF;font-size:12px" href="https://viewstripo.email">Privacy police</a> | <a target="_blank" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#FFFFFF;font-size:12px" href="">Unsubscribe</a></p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding:20px;Margin:0">
                    <table cellpadding="0" cellspacing="0" width="100%" role="none" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                      <tr>
                        <td align="center" valign="top" style="padding:0;Margin:0;width:560px">
                          <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style="mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px">
                            <tr>
                              <td align="center" class="es-infoblock made_with" style="padding:0;Margin:0;line-height:14px;font-size:0;color:#CCCCCC">
                                <a target="_blank" href="https://viewstripo.email/?utm_source=templates&utm_medium=email&utm_campaign=product_update_1&utm_content=it_happens_to_everyone" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#CCCCCC;font-size:12px">
                                  <img src="https://efbutg.stripocdn.email/content/guids/CABINET_09023af45624943febfa123c229a060b/images/7911561025989373.png" alt width="125" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic">
                                </a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
</body>
</html>`;











const classes = {
    back: {
        background: "#fff",
        padding: "7px 15px",
        margin: "0"
    },
    foo: {
        border: "1px solid #c4c4c4"
    },
    button: {
        background: themeFile.palette.primary.light,
        color: "white",
        textDecoration: 'none',
        padding: "15px 30px",
        textAlign: "center",
        display: "block",
        margin: "0"
    },
    header: {
        background: themeFile.palette.secondary.light,
        padding: '7px 15px',
        textAlign: "center",
        margin: "0"
    }
}

const PasswordReset = (props) => {

    return (
<div style={{ backgroundColor: '#07023C', width: '100%', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', padding: '0', margin: '0' }}>
      <table
        className="es-wrapper"
        width="100%"
        cellspacing="0"
        cellpadding="0"
        role="none"
        style={{ borderCollapse: 'collapse', borderSpacing: '0px', padding: '0', margin: '0', width: '100%', height: '100%', backgroundRepeat: 'repeat', backgroundPosition: 'center top', backgroundColor: '#07023C' }}
      >
        <tr>
          <td valign="top" style={{ padding: '0', margin: '0' }}>
            <table className="es-content" cellspacing="0" cellpadding="0" align="center" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', tableLayout: 'fixed !important', width: '100%' }}>
              <tr>
                <td align="center" style={{ padding: '0', margin: '0' }}>
                  <table
                    className="es-content-body"
                    style={{
                      borderCollapse: 'collapse',
                      borderSpacing: '0px',
                      backgroundColor: '#ffffff',
                      backgroundRepeat: 'no-repeat',
                      width: '600px',
                      backgroundImage: 'url(https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_0Ia.png)',
                      backgroundPosition: 'center center',
                    }}
                    cellspacing="0"
                    cellpadding="0"
                    bgcolor="#ffffff"
                    align="center"
                    background="https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_0Ia.png"
                    role="none"
                  >
                    <tr>
                      <td align="left" style={{ margin: '0', paddingBottom: '10px', paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
                        <table cellpadding="0" cellspacing="0" width="100%" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                          <tr>
                            <td className="es-m-p0r" valign="top" align="center" style={{ padding: '0', margin: '0', width: '560px' }}>
                              <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                <tr>
                                  <td align="center" style={{ padding: '0', margin: '0', fontSize: '0px' }}>
                                    <a target="_blank" href="https://viewstripo.email" style={{ textDecoration: 'underline', color: '#26C6DA', fontSize: '14px' }}>
                                      <img src="https://efbutg.stripocdn.email/content/guids/CABINET_b78baa9630348146026decca4228d0f23b4c6a4aca4c497342c6d6a709e175ae/images/smallinnovationslogo.png" alt="Logo" style={{ display: 'block', border: '0', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic' }} title="Logo" height="100" />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ margin: '0', paddingLeft: '20px', paddingRight: '20px', paddingTop: '30px', paddingBottom: '30px' }}>
                        <table width="100%" cellspacing="0" cellpadding="0" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                          <tr>
                            <td className="es-m-p0r es-m-p20b" valign="top" align="center" style={{ padding: '0', margin: '0', width: '560px' }}>
                              <table width="100%" cellspacing="0" cellpadding="0" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                <tr>
                                  <td align="center" style={{ padding: '0', margin: '0' }}>
                                    <h1 style={{ margin: '0', lineHeight: '53px', fontFamily: 'Orbitron, sans-serif', fontSize: '44px', fontStyle: 'normal', fontWeight: 'bold', color: '#10054D' }}>
                                      &nbsp;We got a request to reset your&nbsp;password
                                    </h1>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" style={{ padding: '0', margin: '0', paddingBottom: '10px', paddingTop: '15px', fontSize: '0px' }}>
                                    <a target="_blank" href="https://viewstripo.email" style={{ textDecoration: 'underline', color: '#26C6DA', fontSize: '14px' }}>
                                      <img className="adapt-img" src="https://efbutg.stripocdn.email/content/guids/CABINET_dee64413d6f071746857ca8c0f13d696/images/852converted_1x3.png" alt style={{ display: 'block', border: '0', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic' }} height="300" />
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" style={{ padding: '0', margin: '0', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <p style={{ margin: '0', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', lineHeight: '21px', color: '#333333', fontSize: '14px' }}>
                                      &nbsp;Forgot your password? No problem - it happens to everyone!
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" style={{ padding: '0', margin: '0', paddingTop: '15px', paddingBottom: '15px' }}>
                                    <span className="es-button-border" style={{ borderStyle: 'solid', borderColor: '#26C6DA', background: '#26C6DA', borderWidth: '4px', display: 'inline-block', borderRadius: '10px', width: 'auto' }}>
                                      <a
                                        href={appVars.lightwerksUrl + "reset-password/" + props.id}
                                        className="es-button"
                                        
                                        style={{
                                          textDecoration: 'none',
                                          color: '#FFFFFF',
                                          fontSize: '20px',
                                          padding: '10px 25px 10px 30px',
                                          display: 'inline-block',
                                          background: '#26C6DA',
                                          borderRadius: '10px',
                                          fontFamily: 'arial, "helvetica neue", helvetica, sans-serif',
                                          fontWeight: 'normal',
                                          fontStyle: 'normal',
                                          lineHeight: '24px',
                                          width: 'auto',
                                          textAlign: 'center',
                                        }}
                                      >
                                        Reset Your Password
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="center" style={{ padding: '0', margin: '0', paddingTop: '10px', paddingBottom: '10px' }}>
                                    <p style={{ margin: '0', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', lineHeight: '21px', color: '#333333', fontSize: '14px' }}>
                                      If you ignore this message, your password won't be changed.
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table cellpadding="0" cellspacing="0" className="es-content" align="center" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', tableLayout: 'fixed !important', width: '100%' }}>
              <tr>
                <td align="center" style={{ padding: '0', margin: '0' }}>
                  <table bgcolor="#10054D" className="es-content-body" align="center" cellpadding="0" cellspacing="0" style={{ borderCollapse: 'collapse', borderSpacing: '0px', backgroundColor: '#10054d', width: '600px' }} role="none">
                    <tr>
                      <td
                        align="left"
                        background="https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_sSY.png"
                        style={{
                          margin: '0',
                          paddingLeft: '20px',
                          paddingRight: '20px',
                          paddingTop: '35px',
                          paddingBottom: '35px',
                          backgroundImage: 'url(https://efbutg.stripocdn.email/content/guids/CABINET_0e8fbb6adcc56c06fbd3358455fdeb41/images/vector_sSY.png)',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'left center',
                        }}
                      >
                        <table cellpadding="0" cellspacing="0" className="es-left" align="left" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'left' }}>
                          <tr>
                            <td className="es-m-p20b" align="left" style={{ padding: '0', margin: '0', width: '69px' }}>
                              <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                <tr>
                                  <td align="center" className="es-m-txt-l" style={{ padding: '0', margin: '0', fontSize: '0px' }}>
                                    <a target="_blank" href="https://viewstripo.email" style={{ textDecoration: 'underline', color: '#26C6DA', fontSize: '14px' }}>
                                      <img src="https://efbutg.stripocdn.email/content/guids/CABINET_dee64413d6f071746857ca8c0f13d696/images/group_118_lFL.png" alt style={{ display: 'block', border: '0', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic' }} width="69" />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <table cellpadding="0" cellspacing="0" className="es-right" align="right" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'right' }}>
                          <tr>
                            <td align="left" style={{ padding: '0', margin: '0', width: '471px' }}>
                              <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                <tr>
                                  <td align="left" style={{ padding: '0', margin: '0' }}>
                                    <h3 style={{ margin: '0', lineHeight: '34px', fontFamily: 'Orbitron, sans-serif', fontSize: '28px', fontStyle: 'normal', fontWeight: 'bold', color: '#ffffff' }}>
                                      <b>Real people. Here to help.</b>
                                    </h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td align="left" style={{ padding: '0', margin: '0', paddingBottom: '5px', paddingTop: '10px' }}>
                                    <p style={{ margin: '0', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', lineHeight: '21px', color: '#ffffff', fontSize: '14px' }}>
                                      Have a question? Give us a call at&nbsp;
                                      <strong>
                                        <a href="tel:(000)1234567899" target="_blank" style={{ textDecoration: 'underline', color: '#26C6DA', fontSize: '14px' }}>
                                          (000) 1234 5678 99
                                        </a>
                                      </strong>
                                      &nbsp;to chat with a Customer Success representative.
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table cellpadding="0" cellspacing="0" className="es-footer" align="center" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', tableLayout: 'fixed !important', width: '100%', backgroundColor: 'transparent', backgroundRepeat: 'repeat', backgroundPosition: 'center top' }}>
              <tr>
                <td align="center" style={{ padding: '0', margin: '0' }}>
                  <table bgcolor="#ffffff" className="es-footer-body" align="center" cellpadding="0" cellspacing="0" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', backgroundColor: '#FFFFFF', width: '600px' }}>
                    <tr>
                      <td align="left" style={{ padding: '0', margin: '0', paddingLeft: '20px', paddingRight: '20px', paddingTop: '30px' }}>
                        <table cellpadding="0" cellspacing="0" width="100%" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                          <tr>
                            <td align="center" valign="top" style={{ padding: '0', margin: '0', width: '560px' }}>
                              <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                <tr>
                                  <td align="center" style={{ padding: '0', margin: '0' }}>
                                    <h2 style={{ margin: '0', lineHeight: '43px', fontFamily: 'Orbitron, sans-serif', fontSize: '36px', fontStyle: 'normal', fontWeight: 'bold', color: '#10054D' }}>Is this newsletter helpful?</h2>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td className="esdev-adapt-off" align="left" style={{ margin: '0', paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '30px' }}>
                        <table cellpadding="0" cellspacing="0" className="esdev-mso-table" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', width: '560px' }}>
                          <tr>
                            <td className="esdev-mso-td" valign="top" style={{ padding: '0', margin: '0' }}>
                              <table cellpadding="0" cellspacing="0" className="es-left" align="left" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'left' }}>
                                <tr className="es-mobile-hidden">
                                  <td align="left" style={{ padding: '0', margin: '0', width: '63px' }}>
                                    <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                      <tr>
                                        <td align="center" height="40" style={{ padding: '0', margin: '0' }}></td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ padding: '0', margin: '0', width: '20px' }}></td>
                            <td className="esdev-mso-td" valign="top" style={{ padding: '0', margin: '0' }}>
                              <table cellpadding="0" cellspacing="0" className="es-left" align="left" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'left' }}>
                                <tr>
                                  <td align="left" style={{ padding: '0', margin: '0', width: '63px' }}>
                                    <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                      <tr>
                                        <td align="center" style={{ padding: '0', margin: '0' }}>
                                          <span className="es-button-border" style={{ borderStyle: 'solid', borderColor: '#26C6DA', background: '#26C6DA', borderWidth: '4px', display: 'inline-block', borderRadius: '10px', width: 'auto' }}>
                                            <a href="https://viewstripo.email" className="es-button es-button-1637938355061" target="_blank" style={{ textDecoration: 'none', color: '#FFFFFF', fontSize: '16px', padding: '5px 15px', display: 'inline-block', background: '#26C6DA', borderRadius: '10px', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', fontWeight: 'normal', fontStyle: 'normal', lineHeight: '19px', width: 'auto', textAlign: 'center' }}>
                                              1
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ padding: '0', margin: '0', width: '20px' }}></td>
                            <td className="esdev-mso-td" valign="top" style={{ padding: '0', margin: '0' }}>
                              <table cellpadding="0" cellspacing="0" className="es-left" align="left" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'left' }}>
                                <tr>
                                  <td align="left" style={{ padding: '0', margin: '0', width: '63px' }}>
                                    <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                      <tr>
                                        <td align="center" style={{ padding: '0', margin: '0' }}>
                                          <span className="es-button-border" style={{ borderStyle: 'solid', borderColor: '#26C6DA', background: '#26C6DA', borderWidth: '4px', display: 'inline-block', borderRadius: '10px', width: 'auto' }}>
                                            <a href="https://viewstripo.email" className="es-button es-button-1637938414709" target="_blank" style={{ textDecoration: 'none', color: '#FFFFFF', fontSize: '16px', padding: '5px 15px', display: 'inline-block', background: '#26C6DA', borderRadius: '10px', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', fontWeight: 'normal', fontStyle: 'normal', lineHeight: '19px', width: 'auto', textAlign: 'center' }}>
                                              2
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ padding: '0', margin: '0', width: '20px' }}></td>
                            <td className="esdev-mso-td" valign="top" style={{ padding: '0', margin: '0' }}>
                              <table cellpadding="0" cellspacing="0" className="es-left" align="left" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'left' }}>
                                <tr>
                                  <td align="left" style={{ padding: '0', margin: '0', width: '63px' }}>
                                    <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                      <tr>
                                        <td align="center" style={{ padding: '0', margin: '0' }}>
                                          <span className="es-button-border" style={{ borderStyle: 'solid', borderColor: '#26C6DA', background: '#26C6DA', borderWidth: '4px', display: 'inline-block', borderRadius: '10px', width: 'auto' }}>
                                            <a href="https://viewstripo.email" className="es-button es-button-1637938422665" target="_blank" style={{ textDecoration: 'none', color: '#FFFFFF', fontSize: '16px', padding: '5px 15px', display: 'inline-block', background: '#26C6DA', borderRadius: '10px', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', fontWeight: 'normal', fontStyle: 'normal', lineHeight: '19px', width: 'auto', textAlign: 'center' }}>
                                              3
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ padding: '0', margin: '0', width: '20px' }}></td>
                            <td className="esdev-mso-td" valign="top" style={{ padding: '0', margin: '0' }}>
                              <table cellpadding="0" cellspacing="0" className="es-left" align="left" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'left' }}>
                                <tr>
                                  <td align="left" style={{ padding: '0', margin: '0', width: '63px' }}>
                                    <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                      <tr>
                                        <td align="center" style={{ padding: '0', margin: '0' }}>
                                          <span className="es-button-border" style={{ borderStyle: 'solid', borderColor: '#26C6DA', background: '#26C6DA', borderWidth: '4px', display: 'inline-block', borderRadius: '10px', width: 'auto' }}>
                                            <a href="https://viewstripo.email" className="es-button es-button-1637938430832" target="_blank" style={{ textDecoration: 'none', color: '#FFFFFF', fontSize: '16px', padding: '5px 15px', display: 'inline-block', background: '#26C6DA', borderRadius: '10px', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', fontWeight: 'normal', fontStyle: 'normal', lineHeight: '19px', width: 'auto', textAlign: 'center' }}>
                                              4
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ padding: '0', margin: '0', width: '20px' }}></td>
                            <td className="esdev-mso-td" valign="top" style={{ padding: '0', margin: '0' }}>
                              <table cellpadding="0" cellspacing="0" className="es-left" align="left" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'left' }}>
                                <tr>
                                  <td align="left" style={{ padding: '0', margin: '0', width: '63px' }}>
                                    <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                      <tr>
                                        <td align="center" style={{ padding: '0', margin: '0' }}>
                                          <span className="es-button-border" style={{ borderStyle: 'solid', borderColor: '#26C6DA', background: '#26C6DA', borderWidth: '4px', display: 'inline-block', borderRadius: '10px', width: 'auto' }}>
                                            <a href="https://viewstripo.email" className="es-button es-button-1637938437176" target="_blank" style={{ textDecoration: 'none', color: '#FFFFFF', fontSize: '16px', padding: '5px 15px', display: 'inline-block', background: '#26C6DA', borderRadius: '10px', fontFamily: 'arial, "helvetica neue", helvetica, sans-serif', fontWeight: 'normal', fontStyle: 'normal', lineHeight: '19px', width: 'auto', textAlign: 'center' }}>
                                              5
                                            </a>
                                          </span>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style={{ padding: '0', margin: '0', width: '20px' }}></td>
                            <td className="esdev-mso-td" valign="top" style={{ padding: '0', margin: '0' }}>
                              <table cellpadding="0" cellspacing="0" className="es-right" align="right" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px', float: 'right' }}>
                                <tr className="es-mobile-hidden">
                                  <td align="left" style={{ padding: '0', margin: '0', width: '62px' }}>
                                    <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                      <tr>
                                        <td align="center" height="40" style={{ padding: '0', margin: '0' }}></td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ padding: '20px', margin: '0' }}>
                        <table cellpadding="0" cellspacing="0" width="100%" role="none" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                          <tr>
                            <td align="center" valign="top" style={{ padding: '0', margin: '0', width: '560px' }}>
                              <table cellpadding="0" cellspacing="0" width="100%" role="presentation" style={{ borderCollapse: 'collapse', borderSpacing: '0px' }}>
                                <tr>
                                  <td align="center" className="es-infoblock made_with" style={{ padding: '0', margin: '0', lineHeight: '14px', fontSize: '0', color: '#CCCCCC' }}>
                                    <a target="_blank" href="https://viewstripo.email/?utm_source=templates&utm_medium=email&utm_campaign=product_update_1&utm_content=it_happens_to_everyone" style={{ textDecoration: 'underline', color: '#CCCCCC', fontSize: '12px' }}>
                                      <img src="https://efbutg.stripocdn.email/content/guids/CABINET_09023af45624943febfa123c229a060b/images/7911561025989373.png" alt width="125" style={{ display: 'block', border: '0', outline: 'none', textDecoration: 'none', msInterpolationMode: 'bicubic' }} />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>

    );
};

PasswordReset.propTypes = {

};

export default PasswordReset;
