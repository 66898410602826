import React, { useState, useEffect } from "react";
import { style } from "./index.scss";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Button,
    Snackbar,
    TextField,
    MenuItem,
    Grid,
    IconButton,

} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ClipboardJS from "clipboard";
import appVars from '../../appVars.json';
import Prism from 'prismjs';
import { FileCopy } from "@material-ui/icons";
import Conversions from '../conversions';


const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    table: {
        marginTop: "0"
    },
    title: {
        borderBottom: "1px solid #000",
    },
    pos: {
        marginBottom: 12,
    },
    copylabel: {
    },
    paper: {
        padding: '1em',
        margin: '.5em 0',
        background: "linear-gradient(225deg, rgba(57,156,219,1) 10%, rgba(0,195,164,1) 50%)",
        textAlign: "center",
        color: "white"
    },
    divider: {
        margin: "1em 0"
    }
});

var btn = new ClipboardJS(".copy");
const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
const id = getLastItem(window.location.href);

const Trackers = (props) => {
    const [data, setData] = useState(props);

    const classes = useStyles();
    const [hasError, setErrors] = useState(false);
    const [copySuccessShow, setCopySuccessShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [trackers, setTrackers] = useState([]);
    const [event, setEvent] = useState("");
    const [loading, setLoading] = useState([true]);
    const [copySuccess, setCopySuccess] = useState("");





    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrors(false);
        setCopySuccessShow(false);
    };

    async function fetchData() {
        const res = await fetch(appVars.api_url + "/gettrackers/" + id);
        res
            .json()
            .then((res) => {
                setUsers(res);
                //  console.log(appVars.api_url + "/gettrackers/" + id);
                setLoading(false);
            })
            .catch((err) => setErrors(true));

        const res2 = await fetch(appVars.api_url + "/gettrackers/all");
        res2
            .json()
            .then((res2) => {
                setTrackers(res2);
                //  console.log(appVars.api_url + "/gettrackers/" + id);
                setLoading(false);
            })
            .catch((err) => setErrors(true));



    }



    useEffect(() => {
    
            fetchData();
     
    
        setData(props);
    
        var clipboard = new ClipboardJS("#copy-script,#copy-event");
        clipboard.on("success", function (e) {
            setCopySuccess(e.action + ": " + e.text);
            setCopySuccessShow(true);
            e.clearSelection();
        });
    
        // Cleanup on unmount to avoid memory leaks
        return () => {
            clipboard.destroy();
        };
    }, []);


    return (
        <div className={style}>
            <Grid container spacing={3}>

                <Grid item xl={8} md={9} sm={8} xs={12}>

                    <TextField
                        select
                        fullWidth
                        id="trackername"
                        name="trackername"
                        label="Event to Track"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            setEvent(e.target.value);
                        }}

                    >
                        {trackers.map((option) => (
                            <MenuItem value={option.pkRecId}>{option.trackername}</MenuItem>
                        ))}

                    </TextField>

                </Grid>
                <Grid item xl={4} md={3} sm={4} xs={12}>
                    <Button variant="contained" fullWidth size="medium" color="primary" onClick={(e) => {
                        var data = {
                            "pkRecId": "1",
                            "clientid": id,
                            "trackname": event
                        };

                        const re3 = fetch(appVars.api_url + "/tracker/add",
                            {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(data)
                            }
                        );
                        re3
                            .then((res) => {
                                // console.log(res);
                                fetchData();
                                setCopySuccess("Event Added");
                                setCopySuccessShow(true);
                            });



                    }}
                    >Add New Event</Button>



                </Grid>
                <Grid item xl={12} md={12}>
                    <div className={classes.table}>
                        <MaterialTable
                            style={{ boxShadow: 'none', border: '1px solid #ccc' }}
                            isLoading={loading}
                            columns={[
                                { title: "profileId", field: "profileId", hidden: true },
                                {
                                    title: "Trackers", field: "trackername",
                                    editComponent: (rowData) => (
                                        <TextField select label="Select Tracker" helperText="Please select available tracker"
                                        >
                                            {users.map((option) => (
                                                <MenuItem key={option.pkRecId} value={option.pkRecId}>
                                                    <Typography variant="body2" > {option.trackername} </Typography>
                                                </MenuItem>
                                            ))}

                                        </TextField>
                                    )
                                },
                                { title: "Event Name", field: "eventname" }


                            ]}
                            detailPanel={[
                                {
                                    tooltip: 'Show Request',
                                    icon: 'code',
                                    render: rowData => {
                                        let d = rowData.eventname.toLowerCase();
                                        d = d.replace(' ', '-');
                                        Prism.highlightAll();
                                        return (
                                            <div style={{ background: "#000", color: "#fff" }}>
                                                <pre><code className="language-html copy-event">
                                                    {"<!-- Facebook Server Conversion Event--><script>/** FACEBOOK API CALL **/ var _d = {{generateGUID}};_dwa.send('" + d + "',{amount:{{Get Donation Amount}},event_id:_d});</script><!-- Coding by DigitalWerks -->"}
                                                </code></pre>
                                                <IconButton id="copy-script" variant="outlined" color="primary" className={classes.copylabel} data-clipboard-target=".copy-event" size="small"><FileCopy ></FileCopy></IconButton> <Typography variant="body">Facebook Purchase Event Script</Typography>

                                            </div>
                                        )

                                    },
                                }]}


                            data={users}
                            title="Events being tracked"
                            options={{
                                grouping: false,
                                pageSize: 3,
                                paging: false,
                                pageSizeOptions: [2, 5, 10],
                                showTitle: false,
                                search: false
                            }}
                        />
                    </div>

                </Grid>
                <Grid item xl={12} md={12}>
                    <Conversions sdate={data.sdate} edate={data.edate}></Conversions>
                </Grid>
            </Grid>




            <Snackbar open={hasError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    The data can not be pulled at this time.
                    <Button color="primary" onClick={window.location.reload}>
                        Refresh
                    </Button>
                </Alert>
            </Snackbar>

            <Snackbar
                open={copySuccessShow}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success">
                    {copySuccess}
                </Alert>
            </Snackbar>
        </div>

    );
};

Trackers.propTypes = {

};

export default Trackers;
