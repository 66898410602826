import React, { useState, useEffect } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { lightenHexColor } from "../../../dwi-functions";
import { DateRangePicker } from 'materialui-daterange-picker';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { FcCalendar } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
    cal: {
        position: 'absolute',
        right: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        zIndex: 9999
    },
}));

const DateRangeSelector = (props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sdate = searchParams.get('sdate');
    const edate = searchParams.get('edate');
    var _d = sdate ? new Date(sdate) : new Date(props.sDate);
    var _e = edate ? new Date(edate) : new Date(props.eDate);

    /*
      console.log('Start Date:', _d);
      console.log('End Date:', _e);
    */



    const [open, setOpen] = useState(false);
    const toggle = (e) => setOpen(!open);
    const theme = createTheme({
        palette: {
            primary: {
                main: props.themeColor.primary, // Assuming darkenHexColor returns a valid color
            },
            secondary: {
                main: props.themeColor.secondary, // Assuming the same
            },
        }
    });
    const classes = useStyles();


    useEffect(() => {


    }, [props]);



    return (
        <div>

            <MuiPickersUtilsProvider utils={MomentUtils}>
                {(props.icon === true ?
                    <IconButton aria-label="" size='small'
                        onClick={(e) => {
                            e.preventDefault();
                            setOpen(!open);
                        }}>
                        <FcCalendar size={30} />

                    </IconButton>
                    :
                    <Button
                        fullWidth
                        type="text"
                        color='primary'
                        size="small"
                        endIcon={<FcCalendar />}
                        variant="contained"
                        margin="dense"
                        style={{ background: lightenHexColor(theme.palette.primary.main, 20), marginBottom: theme.spacing(2), fontWeight: 'bold' }}
                        onClick={(e) => {
                            e.preventDefault();
                            setOpen(!open);
                        }}

                    >{new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(_d.toISOString().split('T')[0]))} - {new Intl.DateTimeFormat('en-US', { month: 'long', day: '2-digit', year: 'numeric', timeZone: "UTC" }).format(new Date(_e.toISOString().split('T')[0]))}
                    </Button>

                )}




                <div className={classes.cal}>
                    <DateRangePicker
                        startText="Start date"
                        open={open}
                        initialDateRange={{
                            startDate: _d,
                            endDate: new Date()
                        }}
                        position="top"
                        toggle={toggle}
                        onChange={(e) => {
                            props.childToParent(e);
                            toggle();
                        }}
                        showOutsideDays={false}
                        fixedWeeks
                    /></div>


            </MuiPickersUtilsProvider>

        </div>
    );
};

DateRangeSelector.propTypes = {

};

export default DateRangeSelector;
